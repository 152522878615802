import icon from './icon.png';
import logo from './logo.png';
import './App.css';
import YoutubeBackground from 'react-youtube-background';

function App() {
  return (
    <YoutubeBackground className="youtube" videoId="5_3WfzruV9o" playerOptions={{controls:0, modestbranding:1}}>
    <div className="App">
      <body>
        <div className="tint"/>

        <div className="logos">
          <img src={icon} className="appicon"/>
          <img src={logo} className="logo"/>
        </div>
        
        <div className="centerparent">
          <div className="badgecontainer">
            <a className="badge" href='https://play.google.com/store/apps/details?id=com.minosapps.shiritorimeiro&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img width="254px" alt='Google Play で手に入れよう' src='https://play.google.com/intl/en_us/badges/static/images/badges/ja_badge_web_generic.png'/></a>
          </div>
          <div className="badgecontainer">
            <a className="badge" href="https://apps.apple.com/us/app/%E3%81%97%E3%82%8A%E3%81%A8%E3%82%8A%E8%BF%B7%E8%B7%AF/id1542949663?itsct=apps_box&amp;itscg=30200"
            style={{display:"inline-block", overflow: "hidden", width: "250px", height: "73px"}}><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ja-JP?size=250x83&amp;releaseDate=1449792000&h=045c87fc3e6c6c303208664b5c6878c4" alt="Download on the App Store" style={{width: "250px", height: "69px"}}></img></a>
          </div>
        </div>


        <footer>
          <a className="footerlink" href="privacy">Privacy Policy</a>
          <a className="footerlink" href="terms">Terms and Conditions</a>
        </footer>

      </body>
    </div>
    </YoutubeBackground>
  );
}

export default App;
